import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import Header from './header';

const GlobalStyles = createGlobalStyle`
  :root {
      --color-bg: 12, 20, 31;
      --color-text: 255, 255, 255;
  }

  * {
      box-sizing: border-box;
  }

  body {
      margin: 0;
      background: rgb(var(--color-bg));
      color: rgb(var(--color-text));
      font-family: 'Press Start 2P';
      line-height: 1.4;
  }

  h1, h2, h3, h4 {
      font-weight: 400;
  }

  a {
      color: #92F;
  }

  a:hover {
      color: #F29;
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Header />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
