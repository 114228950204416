import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPageContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  font: calc(32px + 1vw) / 1 Monoton;
  word-spacing: 0.35em;

  .emojis {
    font-size: calc(40px + 1vw);
  }

  h2 {
    margin: 0;
  }

  p {
    margin: 1em 0 0;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 not found" />

    <NotFoundPageContainer>
      <div className="emojis">
        <span role="img" aria-label="chequered flag emoji">
          🏁
        </span>
        <span role="img" aria-label="racing car emoji">
          🏎
        </span>
        <sub>
          <small>
            <span role="img" aria-label="dash emoji">
              💨
            </span>
          </small>
        </sub>
        <span role="img" aria-label="wastebasket emoji">
          🗑
        </span>
      </div>
      <p>OH NO...</p>
      <h2>NOT FOUND !</h2>
    </NotFoundPageContainer>
  </Layout>
);

export default NotFoundPage;
