import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Nav = styled.nav`
  position: fixed;
  perspective: 1000px;
  padding: 40px 20px;
  top: 0;
  right: 0;
  text-align: right;

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .nav__item {
    position: relative;
    display: block;
    color: #b81682;
    letter-spacing: 4px;
    font: 900 48px Orbitron;
    text-decoration: none;
    /* text-shadow:
          2px 2px 0 #DC00FF,
          -1px -1px 0 #FFFFFF,
          1px -1px 0 #97FFFD,
          -1px 1px 0 #97FFFD,
          1px 1px 0 #97FFFD; */
    text-shadow: 2px 2px 0 #470052, -1px -1px 0 #333333, 1px -1px 0 #41706f,
      -1px 1px 0 #41706f, 1px 1px 0 #41706f;
    transform: rotateY(-45deg);
    transition: 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .nav__item:hover {
    z-index: 2;
    color: #fff;
    text-shadow: none;
    transform: rotateY(-44deg);
  }

  .nav__item + .nav__item {
    margin-top: 24px;
  }

  .nav__item[aria-current='page'] {
    color: #fff;
    text-shadow: none;
  }
`;

const Header = () => (
  <header>
    <Nav>
      <Link className="nav__item" to="/">
        Home
      </Link>
      <Link className="nav__item" to="/work/">
        Work
      </Link>
      <Link className="nav__item" to="/about/">
        About
      </Link>
    </Nav>
  </header>
);

export default Header;
